import {
    ButtonGroup,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
} from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import { useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../hooks/useApi';
import ComponentTitle from '../ComponentTitle';
import { useModals, MODAL_TYPE } from '../../hooks/useModal';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../atom/SearchInput';
import recursiveTreeFilter from '../../helpers/recursiveTreeFilter';
import getCheckedChildrenNumberRecursive from '../../pages/companies/components/helpers/getCheckedChildrenNumberRecursive';
import CommonButton from '../atom/CommonButton';
import { transformHierarchyToItem } from '../../helpers/groups';
import EsgSubcategoryLevel from './components/EsgSubcategoryLevel';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import { esgFrameworkAutocomplete } from '../../autocomplete';

/**
 *
 * @param transformResult is a list (or the children in an item)
 * @param checkedData is a list of checked items (type + id)
 */
function propagateChecks(transformResult, checkedData, parentType = 'root', parentId = 0) {
    let newCheckedData = [...checkedData];

    // do this recursively for all children
    for (let i = 0; i < transformResult.length; i++) {
        if (transformResult[i].children && transformResult[i].children.length > 0) {
            newCheckedData = propagateChecks(
                transformResult[i].children,
                newCheckedData,
                transformResult[i].type,
                transformResult[i].id
            );
        }
    }

    const currentLevelItems = transformResult.map((e) => {
        return { type: e.type, id: e.id };
    });
    // do we have all checked items on this level?
    if (
        currentLevelItems.filter((e) => {
            return !newCheckedData.find((c) => c.type === e.type && c.id === e.id);
        }).length === 0
    ) {
        // all items on this level are checked
        if (!newCheckedData.find((e) => e.type === parentType && e.id === parentId)) {
            console.log('Added a new item into checkedData', parentType, parentId);
            newCheckedData.push({ type: parentType, id: parentId });
        }
    }

    return newCheckedData;
}

const EsgRequirementsHierarchy = ({
    localCompanyYear,
    setLocalCompanyYear,
    availableYears,
    checkedData,
    setCheckedData,
    reloadData,
    newCompany,
    newSite,
    isEsgScenario = false,
}) => {
    const { companyId, siteId } = useParams();
    const [dataGroups, setDataGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { showModal } = useModals();
    const { notifyError } = useNotify();
    const [esgFramework, setEsgFramework] = useState(null);

    const loadHierarchy = useCallback((targetYear, targetEsgFramework) => {
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
            year: targetYear,
            hasanyesgs: !!isEsgScenario, // show only data that have esgs if isEsgScenario, otherwise show all
            esgFrameworkId: targetEsgFramework,
            optionalCompanyId: companyId,
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) => transformHierarchyToItem(e));
                setDataGroups(transformResult);
                setLoading(false);
                // we got the hierarchy, we got the leaf items in checkedData
                // group them into higher level setCheckedData
                const newCheckedData = propagateChecks(transformResult, checkedData);
                setCheckedData(newCheckedData);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(localCompanyYear, esgFramework);
    }, [localCompanyYear, esgFramework]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    const getCheckedChildrenNumber = useCallback(
        (item) => {
            return getCheckedChildrenNumberRecursive(checkedData, item);
        },
        [checkedData]
    );

    // TODO: right now, we allow the company admins to modify boundaries for closed years as well (that changes the inventory for the closed year too)

    return (
        <Grid item xs={12} sx={{ pt: 2 }}>
            <Paper sx={{ p: 2, width: '100%' }}>
                {!isEsgScenario && (
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ pb: 2 }}
                    >
                        <Grid item style={{ alignSelf: 'center' }}>
                            <ComponentTitle
                                variant="h5"
                                title="ESG Inventory Boundary (Hierarchy)"
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth>
                                <InputLabel id="year-select">Year</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="year-select"
                                    id="demo-simple-select"
                                    value={localCompanyYear}
                                    size="small"
                                    label="Year"
                                    onChange={(e) => {
                                        setLocalCompanyYear(parseInt(e.target.value, 10));
                                    }}
                                >
                                    {availableYears.map((yearItem) => (
                                        <MenuItem key={yearItem} value={yearItem}>
                                            {yearItem}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <ButtonGroup>
                                <CommonButton
                                    onClick={() => {
                                        showModal(MODAL_TYPE.COPY_ESG_TO_ANOTHER_YEAR, {
                                            title: `Are you sure you want to copy ESG inventory boundary from ${localCompanyYear}?`,
                                            content: 'This action is irreversible.',
                                            companyId,
                                            siteId,
                                            year: localCompanyYear,
                                            availableYears,
                                            reloadData: () => reloadData(),
                                        });
                                    }}
                                    disabled={newCompany || newSite}
                                    label="Copy to ..."
                                    buttonType="outlined"
                                />
                                <CommonButton
                                    onClick={() => {
                                        showModal(MODAL_TYPE.COPY_ESG_FROM_ANOTHER_YEAR, {
                                            title: `Are you sure you want to copy ESG inventory boundary to ${localCompanyYear}?`,
                                            content: 'This action is irreversible.',
                                            companyId,
                                            siteId,
                                            year: localCompanyYear,
                                            availableYears,
                                            reloadData: () => reloadData(),
                                        });
                                    }}
                                    disabled={newCompany || newSite}
                                    label="Copy from ..."
                                    buttonType="outlined"
                                />
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    {loading && (
                        <Grid item xs={12}>
                            <CircularProgress size="1.5rem" />
                        </Grid>
                    )}
                    {!isEsgScenario && (
                        <>
                            <Grid item xs={12}>
                                <AutocompleteSelect
                                    initCall={esgFrameworkAutocomplete.init}
                                    autocompleteCall={esgFrameworkAutocomplete.search}
                                    value={esgFramework}
                                    setValue={(ev) => {
                                        setEsgFramework(ev);
                                    }}
                                >
                                    <TextField label="ESG Framework" fullWidth size="small" />
                                </AutocompleteSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <SearchInput fullWidth search={search} setSearch={setSearch} />
                            </Grid>
                        </>
                    )}
                    {filteredData.map((esgCategory) => (
                        <Grid item xs={12} key={`${esgCategory.id} - ${esgCategory.name}`}>
                            {esgCategory.name}
                            {esgCategory.children.map((esgSubcategory) => (
                                <TreeView
                                    key={`${esgCategory.name} - ${esgSubcategory.id} - ${esgSubcategory.name}`}
                                    aria-label="icon expansion"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    defaultExpanded={[]}
                                >
                                    <EsgSubcategoryLevel
                                        key={`${esgSubcategory.id} - ${esgSubcategory.name}`}
                                        checkedData={checkedData}
                                        esg_subcategory={esgSubcategory}
                                        setCheckedData={setCheckedData}
                                        getCheckedChildrenNumber={getCheckedChildrenNumber}
                                    />
                                </TreeView>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default EsgRequirementsHierarchy;
