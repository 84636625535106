import React, { useState } from 'react';
import {
    Grid,
    TextField,
    InputAdornment,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
} from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
    filterJsonConstantsByMeasure,
    unitConverter,
    WITHOUT_UNIT,
} from '../../../helpers/esgUnitConversions';
import FloatInput from '../../../components/atom/FloatInput';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';

// check if the measure is visible, type is default to number since there is a case where we do not need this check
function isMeasureVisible(measure, defaultMeasure, type = 'number') {
    if (type === 'text') {
        return false;
    }
    if (measure && measure === WITHOUT_UNIT) {
        return false;
    }
    if (!measure && defaultMeasure === WITHOUT_UNIT) {
        return false;
    }
    return true;
}

const EsgQuestionField = ({
    id,
    label,
    type,
    defaultMeasure,
    idx,
    values,
    setFieldValue,
    closedYear = false,
    isScenario = false,
    questionName = '',
    guideNote = '',
}) => {
    const [suggesting, setSuggesting] = useState(false);
    const { notifyError } = useNotify();

    async function suggestingValue(value) {
        try {
            setSuggesting(true);
            const response = await doSwaggerCall(
                'Esgs',
                'getEsgSuggestion',
                {},
                {
                    question: value.esg_question?.name || questionName,
                    field:
                        value?.esg_question?.fields?.find((field) => field.id === id)?.label ||
                        label,
                    guidance: value?.esg_question?.guide_note || guideNote,
                }
            );
            setFieldValue(`values[${idx}]`, {
                id,
                value: response.suggestion,
                measure: values.values[idx]?.measure || defaultMeasure,
            });
        } catch (err) {
            notifyError(err);
        } finally {
            setSuggesting(false);
        }
    }

    const commonInputProps = {
        endAdornment: isMeasureVisible(values.values[idx]?.measure, defaultMeasure) && (
            <Grid item>
                {!isMeasureVisible(values.values[idx]?.measure, defaultMeasure, type) ? (
                    <Tooltip title="This unit is not editable" placement="top">
                        <InputAdornment position="end">
                            {values.values[idx]?.measure || defaultMeasure}
                        </InputAdornment>
                    </Tooltip>
                ) : (
                    <FormControl size="small" fullWidth>
                        <InputLabel id="measure-select" />
                        <Select
                            disabled={isScenario || closedYear}
                            labelId="measure-select"
                            className="question-field"
                            value={values.values[idx]?.measure || defaultMeasure}
                            size="small"
                            onChange={(e) => {
                                setFieldValue(`values[${idx}]`, {
                                    id,
                                    value: unitConverter(
                                        values.values[idx]?.value,
                                        values.values[idx]?.measure || defaultMeasure,
                                        e.target.value
                                    ),
                                    measure: e.target.value,
                                });
                            }}
                        >
                            {filterJsonConstantsByMeasure(
                                values.values[idx]?.measure || defaultMeasure
                            ).map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Grid>
        ),
    };

    return (
        <Grid item xs={12} key={id}>
            {type === 'number' ? (
                <FloatInput
                    fullWidth
                    autoComplete="off"
                    label={label}
                    type="number"
                    value={values.values[idx]?.value || ''}
                    onChange={(ev) => {
                        setFieldValue(`values[${idx}]`, {
                            id,
                            value: ev.target.rawValue,
                            measure: values.values[idx]?.measure || defaultMeasure,
                        });
                    }}
                    InputProps={commonInputProps}
                    disabled={isScenario || closedYear}
                    sx={{
                        '& .MuiInputLabel-root': {
                            fontSize: '1.5rem',
                            fontWeight: '700',
                        },
                    }}
                />
            ) : (
                <TextField
                    disabled={isScenario || closedYear}
                    multiline
                    fullWidth
                    autoComplete="off"
                    label={label}
                    value={values.values[idx]?.value || ''}
                    onChange={(ev) => {
                        setFieldValue(`values[${idx}]`, {
                            id,
                            value: ev.target.value,
                            measure: values.values[idx]?.measure || defaultMeasure,
                        });
                    }}
                    InputLabelProps={{
                        shrink: true,
                        sx: { fontSize: '1.5rem', fontWeight: '700' },
                    }}
                    InputProps={{
                        ...commonInputProps,
                        // AI suggestion part
                        endAdornment: (
                            <InputAdornment position="end">
                                {!isScenario && (
                                    <IconButton
                                        sx={{ p: 0 }}
                                        disabled={closedYear}
                                        onClick={() => suggestingValue(values)}
                                        disableRipple
                                    >
                                        {suggesting ? '...' : <AutoFixHighIcon />}
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        </Grid>
    );
};

export default EsgQuestionField;
