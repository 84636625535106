import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import { Box, Container } from '@mui/material';
import LoginPage from './pages/login/LoginPage';
import ForgotPasswordPage from './pages/login/ForgotPasswordPage';
import ResetPasswordModal from './modals/ResetPasswordModal';
import Header from './components/header';
import DatalinesPage from './pages/datalines';
import NotFoundPage from './pages/notfound/index';
import TestPage from './pages/themetest';
import ReportPage from './pages/report';
import EmissionSourceEditPage from './pages/emissionsourceedit/EmissionSourceEditPage';
import EmissionSourcesPage from './pages/emissionsources';
import { useAuth } from './hooks/useAuth';
import CompaniesPage from './pages/companies';
import EditCompanyPage from './pages/companies/pages/EditCompanyPage';
import UsersPage from './pages/users';
import Dashboard from './pages/dashboard';
import ProfilePage from './pages/me';
import CreateUserPage from './pages/users/createuser';
import SitesPage from './pages/sites';
import EditSitePage from './pages/sites/pages/EditSitePage';
import CategoriesPage from './pages/categories';
import EditCategoryPage from './pages/categories/pages/EditCategoryPage';
import SubcategoriesPage from './pages/subcategories';
import EditSubcategoryPage from './pages/subcategories/pages/EditSubcategoryPage';
import LocationsPage from './pages/locations';
import EditLocationPage from './pages/locations/pages/EditLocationPage';
import EgridPickerPage from './pages/egridpicker';
import RequirementsPage from './pages/requirements';
import ScenariosPage from './pages/scenarios';
import AddScenarioPage from './pages/scenarios/pages/AddScenarioPage';
import EditScenarioPage from './pages/scenarios/pages/EditScenarioPage';
import MultipleScenarioPage from './pages/scenarios/pages/MultipleScenarios';
import ConversionLogicTemplateEditPage from './pages/conversionlogictemplateedit/ConversionLogicTemplateEditPage';
import ConversionLogicTemplatesPage from './pages/conversionlogictemplates';
import DashboardTemplatesPage from './pages/dashboardtemplates';
import DashboardTemplateEditPage from './pages/dashboardtemplateedit';
import NewThemeTestPage from './pages/newthemetest';
import { PERMISSIONS, ROLES } from './helpers/constans';
import NotificationsPage from './pages/usernotifications';
import CompanyTagsPage from './pages/companytags';
import EditCompanyTagPage from './pages/companytags/pages/EditCompanyTagPage';
import SuppliersPage from './pages/suppliers';
import EditSupplierPage from './pages/suppliers/pages/EditSupplierPage';
import SurveyPage from './pages/survey';
import IncorrectDatalinesPage from './pages/datalines/pages/IncorrectDatalinesPage';
import VarianceTrackerPage from './pages/variancetracker';
import SurveyDetailsPage from './pages/surveydetails/SurveyDetailsPage';
import AuditLogsPage from './pages/auditlogs';
import EmailLogsPage from './pages/emaillogs';
import EsgsPage from './pages/esgs';
import EsgQuestionsPage from './pages/esgquestions';
import EsgQuestionEditPage from './pages/esgquestionedit/EsgQuestionEditPage';
import EsgSubcategoriesPage from './pages/esghierarchy/esgsubcategories';
import EsgDisclosuresPage from './pages/esghierarchy/esgdisclosures';
import EditEsgCategoryPage from './pages/esghierarchy/esgcategories/EditEsgCategoryPage';
import EditEsgSubcategoryPage from './pages/esghierarchy/esgsubcategories/EditEsgSubcategoryPage';
import EditEsgDisclosurePage from './pages/esghierarchy/esgdisclosures/EditEsgDisclosurePage';
import EsgFrameworksPage from './pages/esghierarchy/esgframeworks';
import EsgCategoriesPage from './pages/esghierarchy/esgcategories';
import EditEsgframeworkPage from './pages/esghierarchy/esgframeworks/EditEsgFrameworkPage';
import EsgFrameworkQuestionsPage from './pages/esghierarchy/esgframeworkquestions';
import EditEsgFrameworkQuestionPage from './pages/esghierarchy/esgframeworkquestions/EditEsgFrameworkQuestionPage';
import ImpSurveyPage from './pages/impsurvey';
import RightSidebarDrawerSpace from './components/header/RightSidebarDrawerSpace';
import EsgProgressTrackerPage from './pages/esgprogresstracker';
import AddEsgScenarioPage from './pages/esgscenarios/AddEsgScenarioPage';
import EditEsgScenarioPage from './pages/esgscenarios/EditEsgScenarioPage';
import EsgScenariosPage from './pages/esgscenarios';
import AirportsPage from './pages/airports';

function PageForEveryone({ children }) {
    const { user } = useAuth();
    if (!user) return <Navigate to="/" />;
    return children;
}

/* only superadmin features */
function PageForSuperadminsOnly({ children }) {
    const { user } = useAuth();
    if (!user || user.role !== ROLES.SUPERADMIN) return <Navigate to="/" />;
    return children;
}

/* only user has specific permission */
function PageForDashboardAndReportOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const dashboardAndReportPermission = userPermissions.includes(PERMISSIONS.VIEW_DASHBOARD);
    if (!user || !dashboardAndReportPermission) return <Navigate to="/" />;
    return children;
}
function PageForDatalinesOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const datalinesPermission = userPermissions.includes(PERMISSIONS.VIEW_DATALINES);
    if (!user || !datalinesPermission) return <Navigate to="/" />;
    return children;
}
function PageForAssignedToOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const assignedToPermission = userPermissions.includes(PERMISSIONS.VIEW_ASSIGNED_TO);
    if (!user || !assignedToPermission) return <Navigate to="/" />;
    return children;
}
function PageForOwnedOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const ownedPermission = userPermissions.includes(PERMISSIONS.VIEW_OWNED);
    if (!user || !ownedPermission) return <Navigate to="/" />;
    return children;
}
function PageForRequirementsOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const requirementsPermission = userPermissions.includes(PERMISSIONS.VIEW_REQUIREMENTS);
    if (!user || !requirementsPermission) return <Navigate to="/" />;
    return children;
}
function PageForScenariosOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const scenariosPermission = userPermissions.includes(PERMISSIONS.MANAGE_SCENARIOS);
    if (!user || !scenariosPermission) return <Navigate to="/" />;
    return children;
}
function PageForManageCompanyOrSuperAdminOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const manageCompanyPermission = userPermissions.includes(PERMISSIONS.MANAGE_COMPANY);
    if (!user || (!manageCompanyPermission && user.role !== ROLES.SUPERADMIN))
        return <Navigate to="/" />;
    return children;
}
function PageForManageCompanyOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const manageCompanyPermission = userPermissions.includes(PERMISSIONS.MANAGE_COMPANY);
    if (!user || !manageCompanyPermission) return <Navigate to="/" />;
    return children;
}
function PageForManageSuppliersOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const manageSuppliersPermission = userPermissions.includes(PERMISSIONS.MANAGE_SUPPLIERS);
    if (!user || !manageSuppliersPermission) return <Navigate to="/" />;
    return children;
}
function PageForManageEsgsOnly({ children }) {
    const { user, userPermissions } = useAuth();
    const manageEsgsPermission = userPermissions.includes(PERMISSIONS.MANAGE_ESGS);
    if (!user || !manageEsgsPermission) return <Navigate to="/" />;
    return children;
}

const App = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Header />
            <Box component="main" my={2} sx={{ flexGrow: 1, pt: 8 }}>
                <Container maxWidth="xl">
                    <Routes>
                        <Route path="/" exact element={<LoginPage />} />
                        <Route path="/forgotpassword" exact element={<ForgotPasswordPage />} />
                        <Route path="resetpassword/:token" element={<ResetPasswordModal />} />
                        <Route path="survey/:token" element={<SurveyPage />} />
                        <Route path="airportdistances" element={<AirportsPage />} />
                        <Route
                            path="/datalines"
                            element={
                                <PageForDatalinesOnly>
                                    <DatalinesPage />
                                </PageForDatalinesOnly>
                            }
                        />
                        <Route
                            path="/datalines/assignedtome"
                            element={
                                <PageForAssignedToOnly>
                                    <DatalinesPage responsibility="assignedToMe" />
                                </PageForAssignedToOnly>
                            }
                        />
                        <Route
                            path="/datalines/ownedbyme"
                            element={
                                <PageForOwnedOnly>
                                    <DatalinesPage responsibility="ownedByMe" />
                                </PageForOwnedOnly>
                            }
                        />
                        <Route
                            path="/datalines/incorrect"
                            element={
                                <PageForSuperadminsOnly>
                                    <IncorrectDatalinesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/report"
                            element={
                                <PageForDashboardAndReportOnly>
                                    <ReportPage />
                                </PageForDashboardAndReportOnly>
                            }
                        />
                        <Route
                            path="/test"
                            element={
                                <PageForSuperadminsOnly>
                                    <TestPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/themetest"
                            element={
                                <PageForSuperadminsOnly>
                                    <NewThemeTestPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/egridpicker"
                            element={
                                <PageForSuperadminsOnly>
                                    <EgridPickerPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/emissionsources"
                            element={
                                <PageForSuperadminsOnly>
                                    <EmissionSourcesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/emissionsources/edit/:emissionSourceId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EmissionSourceEditPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/companies"
                            element={
                                <PageForSuperadminsOnly>
                                    <CompaniesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/companies/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditCompanyPage newCompany />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/companies/edit/:companyId"
                            element={
                                <PageForManageCompanyOnly>
                                    <EditCompanyPage />
                                </PageForManageCompanyOnly>
                            }
                        />
                        <Route
                            path="/companies/:companyId/users"
                            element={
                                <PageForManageCompanyOnly>
                                    <UsersPage />
                                </PageForManageCompanyOnly>
                            }
                        />
                        <Route
                            path="/companies/:companyId/sites"
                            element={
                                <PageForManageCompanyOnly>
                                    <SitesPage />
                                </PageForManageCompanyOnly>
                            }
                        />
                        <Route
                            path="/companies/:companyId/sites/edit/:siteId"
                            element={
                                <PageForManageCompanyOnly>
                                    <EditSitePage />
                                </PageForManageCompanyOnly>
                            }
                        />
                        <Route
                            path="/companies/:companyId/sites/new"
                            element={
                                <PageForManageCompanyOnly>
                                    <EditSitePage newSite />
                                </PageForManageCompanyOnly>
                            }
                        />
                        <Route
                            path="/companies/:companyId/invite"
                            element={
                                <PageForManageCompanyOnly>
                                    <CreateUserPage />
                                </PageForManageCompanyOnly>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <PageForDashboardAndReportOnly>
                                    <Dashboard />
                                </PageForDashboardAndReportOnly>
                            }
                        />
                        <Route
                            path="/locations/:parentId"
                            element={
                                <PageForSuperadminsOnly>
                                    <LocationsPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/locations/:parentId/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditLocationPage newLocation />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/locations/:parentId/edit/:locationId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditLocationPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/categories"
                            element={
                                <PageForSuperadminsOnly>
                                    <CategoriesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/categories/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditCategoryPage newCategory />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/categories/edit/:categoryId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditCategoryPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/categories/:categoryId/subcategories"
                            element={
                                <PageForSuperadminsOnly>
                                    <SubcategoriesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/categories/:categoryId/subcategories/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditSubcategoryPage newSubcategory />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/categories/:categoryId/subcategories/edit/:subcategoryId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditSubcategoryPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/user/me/"
                            element={
                                <PageForEveryone>
                                    <ProfilePage />
                                </PageForEveryone>
                            }
                        />
                        <Route
                            path="/progresstracker"
                            element={
                                <PageForRequirementsOnly>
                                    <RequirementsPage />
                                </PageForRequirementsOnly>
                            }
                        />
                        <Route
                            path="/scenarios"
                            element={
                                <PageForScenariosOnly>
                                    <ScenariosPage />
                                </PageForScenariosOnly>
                            }
                        />
                        <Route
                            path="/scenarios/new"
                            element={
                                <PageForScenariosOnly>
                                    <AddScenarioPage />
                                </PageForScenariosOnly>
                            }
                        />
                        <Route
                            path="/scenarios/edit/:scenarioId"
                            element={
                                <PageForScenariosOnly>
                                    <EditScenarioPage />
                                </PageForScenariosOnly>
                            }
                        />
                        <Route
                            path="/scenarios/scenariocompare"
                            element={
                                <PageForScenariosOnly>
                                    <MultipleScenarioPage />
                                </PageForScenariosOnly>
                            }
                        />
                        <Route
                            path="/conversionlogictemplates"
                            element={
                                <PageForSuperadminsOnly>
                                    <ConversionLogicTemplatesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/conversionlogictemplates/edit/:templateId"
                            element={
                                <PageForSuperadminsOnly>
                                    <ConversionLogicTemplateEditPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/dashboardtemplates"
                            element={
                                <PageForDashboardAndReportOnly>
                                    <DashboardTemplatesPage />
                                </PageForDashboardAndReportOnly>
                            }
                        />
                        <Route
                            path="/dashboardtemplates/new"
                            element={
                                <PageForDashboardAndReportOnly>
                                    <DashboardTemplateEditPage newDashboard />
                                </PageForDashboardAndReportOnly>
                            }
                        />
                        <Route
                            path="/dashboardtemplates/edit/:dashboardId"
                            element={
                                <PageForDashboardAndReportOnly>
                                    <DashboardTemplateEditPage />
                                </PageForDashboardAndReportOnly>
                            }
                        />
                        <Route
                            path="/dashboardtemplates/show/:dashboardId"
                            element={
                                <PageForDashboardAndReportOnly>
                                    <DashboardTemplateEditPage readOnly />
                                </PageForDashboardAndReportOnly>
                            }
                        />
                        <Route
                            path="/notifications"
                            element={
                                <PageForEveryone>
                                    <NotificationsPage />
                                </PageForEveryone>
                            }
                        />
                        <Route
                            path="/companytags"
                            element={
                                <PageForSuperadminsOnly>
                                    <CompanyTagsPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/companytags/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditCompanyTagPage newCompanyTag />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/companytags/edit/:companyTagId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditCompanyTagPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/suppliers"
                            element={
                                <PageForManageSuppliersOnly>
                                    <SuppliersPage />
                                </PageForManageSuppliersOnly>
                            }
                        />
                        <Route
                            path="/suppliers/new"
                            element={
                                <PageForManageSuppliersOnly>
                                    <EditSupplierPage newSupplier />
                                </PageForManageSuppliersOnly>
                            }
                        />
                        <Route
                            path="/suppliers/edit/:supplierId"
                            element={
                                <PageForManageSuppliersOnly>
                                    <EditSupplierPage />
                                </PageForManageSuppliersOnly>
                            }
                        />
                        <Route
                            path="/surveydetails/:surveyId"
                            element={
                                <PageForManageSuppliersOnly>
                                    <SurveyDetailsPage />
                                </PageForManageSuppliersOnly>
                            }
                        />
                        <Route
                            path="/companies/:companyId/variancetracker"
                            element={
                                <PageForManageCompanyOnly>
                                    <VarianceTrackerPage />
                                </PageForManageCompanyOnly>
                            }
                        />
                        <Route
                            path="/auditlogs"
                            element={
                                <PageForSuperadminsOnly>
                                    <AuditLogsPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/emaillogs"
                            element={
                                <PageForSuperadminsOnly>
                                    <EmailLogsPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgs"
                            element={
                                <PageForManageEsgsOnly>
                                    <EsgsPage />
                                </PageForManageEsgsOnly>
                            }
                        />
                        <Route
                            path="/esgs/assignedtome"
                            element={
                                <PageForAssignedToOnly>
                                    <EsgsPage responsibility="assignedToMe" />
                                </PageForAssignedToOnly>
                            }
                        />
                        <Route
                            path="/esgs/ownedbyme"
                            element={
                                <PageForOwnedOnly>
                                    <EsgsPage responsibility="ownedByMe" />
                                </PageForOwnedOnly>
                            }
                        />
                        <Route
                            path="/esgquestions"
                            element={
                                <PageForSuperadminsOnly>
                                    <EsgQuestionsPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgquestions/edit/:esgQuestionId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EsgQuestionEditPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories"
                            element={
                                <PageForSuperadminsOnly>
                                    <EsgCategoriesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgCategoryPage newEsgCategory />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories/edit/:esgCategoryId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgCategoryPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories/:esgCategoryId/esgsubcategories"
                            element={
                                <PageForSuperadminsOnly>
                                    <EsgSubcategoriesPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories/:esgCategoryId/esgsubcategories/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgSubcategoryPage newEsgSubcategory />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="esgcategories/:esgCategoryId/esgsubcategories/edit/:esgSubcategoryId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgSubcategoryPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories/:esgCategoryId/esgsubcategories/:esgSubcategoryId/esgdisclosures"
                            element={
                                <PageForSuperadminsOnly>
                                    <EsgDisclosuresPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories/:esgCategoryId/esgsubcategories/:esgSubcategoryId/esgdisclosures/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgDisclosurePage newEsgDisclosure />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgcategories/:esgCategoryId/esgsubcategories/:esgSubcategoryId/esgdisclosures/edit/:esgDisclosureId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgDisclosurePage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgframeworks"
                            element={
                                <PageForSuperadminsOnly>
                                    <EsgFrameworksPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgframeworks/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgframeworkPage newEsgFramework />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgframeworks/edit/:esgFrameworkId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgframeworkPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgframeworks/:esgFrameworkId/esgframeworkquestions"
                            element={
                                <PageForSuperadminsOnly>
                                    <EsgFrameworkQuestionsPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgframeworks/:esgFrameworkId/esgframeworkquestions/new"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgFrameworkQuestionPage newEsgFrameworkQuestion />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgframeworks/:esgFrameworkId/esgframeworkquestions/edit/:esgFrameworkQuestionId"
                            element={
                                <PageForSuperadminsOnly>
                                    <EditEsgFrameworkQuestionPage />
                                </PageForSuperadminsOnly>
                            }
                        />
                        <Route
                            path="/esgprogresstracker"
                            element={
                                <PageForManageEsgsOnly>
                                    <EsgProgressTrackerPage />
                                </PageForManageEsgsOnly>
                            }
                        />
                        <Route
                            path="/impsurveys"
                            element={
                                <PageForManageCompanyOrSuperAdminOnly>
                                    <ImpSurveyPage />
                                </PageForManageCompanyOrSuperAdminOnly>
                            }
                        />
                        <Route
                            path="/esgscenarios"
                            element={
                                <PageForScenariosOnly>
                                    <EsgScenariosPage />
                                </PageForScenariosOnly>
                            }
                        />
                        <Route
                            path="/esgscenarios/new"
                            element={
                                <PageForScenariosOnly>
                                    <AddEsgScenarioPage />
                                </PageForScenariosOnly>
                            }
                        />
                        <Route
                            path="/esgscenarios/edit/:scenarioId"
                            element={
                                <PageForScenariosOnly>
                                    <EditEsgScenarioPage />
                                </PageForScenariosOnly>
                            }
                        />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Container>
            </Box>
            <RightSidebarDrawerSpace />
        </Box>
    );
};
export default App;
